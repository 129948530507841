import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DropDownIcon from '../../components/shared/icon/composite/DropDownIcon';
import { Option } from '../../components/Option';
import ClientService from '../../services/ClientService';
import { useNavigate } from 'react-router-dom';
import { useCurrentTenantId } from '../../global-state/Clients';

const TenantSelect: FC = () => {
  const [open, setOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const currentTenantId = useCurrentTenantId((x) => x.value);
  const [tenantOptions, setTenantOptions] = useState<Option<string, string>[]>([]);

  useEffect(() => {
    ClientService.getMyClients().then((res) => {
      setTenantOptions(res.data.filter((x) => x.isTenant).map((x) => ({ id: x.tenantId, text: x.name, value: x.tenantId })));
    });
  }, []);

  const onClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setOpen(!open);
  };

  useEffect(() => {
    const mouseDownListener = (e: MouseEvent): void => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', mouseDownListener);

    return () => {
      document.removeEventListener('mousedown', mouseDownListener);
    };
  }, []);

  const selectedTenantOption = useMemo(() => tenantOptions.find((x) => x.id === currentTenantId), [currentTenantId, tenantOptions]);

  const changeTenant = useCallback(
    (tenantId: string) => {
      navigate(`/builder/${tenantId}`);
    },
    [navigate],
  );

  if (tenantOptions.length <= 1) {
    return null;
  }

  return (
    <div ref={popupRef} className="z-popover relative">
      <div className="relative flex cursor-pointer select-none items-center" onClick={onClick}>
        <div className="pr-2 font-medium">{selectedTenantOption?.text}</div>
        <DropDownIcon className="h-4 w-4" open={open} />
      </div>
      {open && (
        <div className="absolute left-0 px-2 py-1" onClick={() => setOpen(false)}>
          <ul className="border-1 min-w-64 max-w-md border-gray-200 bg-white shadow-md">
            {tenantOptions.map((tenant) => (
              <li
                key={tenant.value}
                className="cursor-pointer select-none border-t px-3 py-1 hover:bg-gray-200"
                onClick={() => changeTenant(tenant.value)}
              >
                {tenant.text}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TenantSelect;
